<template>
  <div>
    <h4 class="widget-title">{{title}}</h4>
    <v-row>
      <v-col :md="4" :sm="4" :xs="12">
        <router-link :to="{name: 'pedidos-list'}" title="Clique para ver os pedidos solicitados">
          <v-card class="app-card">
            <v-card-title class="app-card-title">Solicitados</v-card-title>
            <v-card-text class="app-card-content">
              <span v-if="isLoading">Buscando informações...</span>
              <div v-else>
                <div class="widget-content">
                  <div class="widget-content-left">
                    <v-icon size="86px" color="primary">assignment</v-icon>
                  </div>
                  <div class="widget-content-right">
                    <h2 class="text text-qtd">116</h2>
                    <h5 class="text text-process">Pedidos</h5>
                  </div>
                </div>
              </div>
            </v-card-text>
            <div class="widget-content-updated">
              <p>
                <v-icon size="15px">schedule</v-icon>
                <span>Atualizado em: 08/03/2021 15:53</span>
              </p>
            </div>
          </v-card>
        </router-link>
      </v-col>
      <v-col :md="4" :sm="4" :xs="12">
        <router-link :to="{name: 'pedidos-list'}" title="Clique para ver os pedidos solicitados">
          <v-card class="app-card">
            <v-card-title class="app-card-title">Indeferidos</v-card-title>
            <v-card-text class="app-card-content">
              <span v-if="isLoading">Buscando informações...</span>
              <div v-else>
                <div class="widget-content">
                  <div class="widget-content-left">
                    <v-icon size="86px" color="error">assignment_late</v-icon>
                  </div>
                  <div class="widget-content-right">
                    <h2 class="text text-qtd">11</h2>
                    <h5 class="text text-process">Pedidos</h5>
                  </div>
                </div>
              </div>
            </v-card-text>
            <div class="widget-content-updated">
              <p>
                <v-icon size="15px">schedule</v-icon>
                <span>Atualizado em: 08/03/2021 15:53</span>
              </p>
            </div>
          </v-card>
        </router-link>
      </v-col>
      <v-col :md="4" :sm="4" :xs="12">
        <router-link :to="{name: 'pedidos-list'}" title="Clique para ver os pedidos solicitados">
          <v-card class="app-card">
            <v-card-title class="app-card-title">Deferidos</v-card-title>
            <v-card-text class="app-card-content">
              <span v-if="isLoading">Buscando informações...</span>
              <div v-else>
                <div class="widget-content">
                  <div class="widget-content-left">
                    <v-icon size="86px" color="success">assignment_turned_in</v-icon>
                  </div>
                  <div class="widget-content-right">
                    <h2 class="text text-qtd">21</h2>
                    <h5 class="text text-process">Pedidos</h5>
                  </div>
                </div>
              </div>
            </v-card-text>
            <div class="widget-content-updated">
              <p>
                <v-icon size="15px">schedule</v-icon>
                <span>Atualizado em: 08/03/2021 15:53</span>
              </p>
            </div>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Widgets",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isLoading: false
  })
};
</script>

<style lang="scss" scoped>
.widget {
  &-title {
    font-weight: bold !important;
    font-size: 25px !important;
  }
  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &-left {
      .v-icon {
        margin-left: -15px;
      }
    }
    &-right {
      text-align: right;
      h2 {
        margin: 0px;
        font-size: 3.4rem !important;
        line-height: 54px !important;
      }
      h5 {
        margin: 0px;
      }
    }
    &-updated {
      margin: 0px 15px;
      border-top: solid 1px rgba(0, 0, 0, 0.1);
      padding: 5px 0px;
      color: #a2a2a2;
      .v-icon {
        margin-right: 3px;
      }
      p {
        margin: 0px;
        font-style: italic;
      }
      span {
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
}
</style>
