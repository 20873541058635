<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex lg12 sm12 xs12>
        <v-alert border="left" color="blue-grey" dark text>
          <strong>{{ user.name }}</strong>, bem-vindo(a) ao sistema
          <strong>{{ appName }}</strong>.
        </v-alert>
        <v-divider class="mb-5"></v-divider>
        <v-row>
          <v-col :md="3" :sm="3" :xs="12" v-for="(service, index) in services" :key="index">
            <router-link
              :to="{ name: service.urlName }"
              :title="`Clique para solicitar ${service.name}`"
              :disabled="true"
              v-if="service.urlName"
            >
              <v-card outlined class="card-add">
                <div class="card-add__info">
                  <Popover :data="service.documents" />
                </div>
                <div class="card-add__overlay" v-if="!service.urlName && !service.urlExternal"></div>
                <div class="card-add__icon">
                  <v-icon color="white">{{ service.icon }}</v-icon>
                </div>
                <span class="card-add__title">{{ service.name }}</span>
                <span class="card-add__subtitle">
                  {{
                  service.description
                  }}
                </span>
              </v-card>
            </router-link>
            <a
              :href="service.urlExternal"
              :title="`Clique para solicitar ${service.name}`"
              :disabled="true"
              target="_blank"
              v-else
            >
              <v-card outlined class="card-add">
                <div class="card-add__info">
                  <Popover :data="service.documents" />
                </div>
                <div class="card-add__overlay" v-if="!service.urlName && !service.urlExternal"></div>
                <div class="card-add__icon">
                  <v-icon color="white">{{ service.icon }}</v-icon>
                </div>
                <span class="card-add__title">{{ service.name }}</span>
                <span class="card-add__subtitle">
                  {{
                  service.description
                  }}
                </span>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue";
import GlobalStore from "@/store/modules/global";
import { APP_NAME } from "@/commons/config";
import Widgets from "@/components/Widgets/Widgets";
import Popover from "@/components/Popover";

export default {
  components: {
    //Widgets,
    Popover
  },
  data: () => ({
    appName: APP_NAME,
    services: [
      {
        name: "Autovistoria + Laudo Engenheiro Mecânico",
        urlName: "autovistoria-laudo-engenheiro-mecaninco",
        urlExternal: null,
        icon: "assignment",
        description: "",
        documents: ["1. Cartão CNPJ", "2. Cópia CRLV", "3. Certificado de Tacógrafo"]
      },
      // {
      //   name: "Autovistoria",
      //   urlName: "autovistoria",
      //   urlExternal: null,
      //   icon: "assignment",
      //   description: "",
      //   documents: ["1. Cartão CNPJ", "2. Cópia CRLV"]
      // },
      {
        name: "Renovação Cadastro Empresa",
        urlName: "renovacao-cadastro-empresa",
        urlExternal: null,
        icon: "assignment",
        description: "",
        documents: [
          "1. Requerimento à Coordenadoria de Transportes da ARCE solicitando a renovação da transportadora, anexando os documentos abaixo exigidos, com firma reconhecida, nome, telefone e e-mail do responsável da transportadora. Caso o requerimento seja encaminhado pelo procurador da transportadora, apresentar procuração concedendo poderes específicos para representar a outorgante junto à ARCE;",
          "2. Ato Constitutivo, estatuto ou contrato social em vigor, devidamente registrado, em se tratando de sociedades comerciais, e no caso de sociedades por ações, acompanhado de documentos de eleição de seus administradores;",
          "3. Certidão Negativa de falência e concordata expedida pelo distribuidor da sede da pessoa jurídica, ou de execução patrimonial, expedida no domicílio da pessoa física. Caso o requerimento seja encaminhado pelo procurador da transportadora, apresentar procuração concedendo poderes específicos para representar a outorgante junto à ARCE;",
          "4. Certidão negativa de débito pecuniário (ARCE / DETRAN/CE);",
          "5. Apólice de seguro de responsabilidade civil, atualizada, assinada e carimbada pelo corretor de seguros e boleto com comprovante de pagamento;",
          "6. Balanço Patrimonial e demonstrações contábeis do último exercício social (exceto ME, EPP, Empresário Individual)."
        ]
      },
      {
        name: "Renovação de Veículo",
        urlName: "renovacao-registro-veiculo",
        urlExternal: null,
        icon: "assignment",
        description: "",
        documents: [
          "1. Requerimento assinado pelo responsável da transportadora especificando o pleito e contendo o modelo e ano do chassi e da carroceria, número do chassi, placa e capacidade de lotação. Caso o requerimento seja encaminhado pelo procurador da transportadora, apresentar procuração concedendo poderes específicos para representar a outorgante junto à ARCE;",
          "2. Certificado de propriedade (CRLV) ou contrato de arrendamento mercantil;",
          "3. Laudo técnico com ART;",
          "4. Apólice de seguro de responsabilidade civil, atualizada, assinada e carimbada pelo corretor de seguros e boleto com comprovante de pagamento;",
          "ATENÇÃO – A empresa deve estar com a renovação em dias."
        ]
      },
      {
        name: "Envio Documentação Seguro",
        urlName: "envio-documentacao-seguro",
        urlExternal: null,
        icon: "assignment",
        description: "",
        documents: [
          "1. Requerimento à Coordenadoria de Transportes da ARCE apresentando as apólices de seguro, anexando os documentos abaixo exigidos, com firma reconhecida, nome, telefone e e-mail do responsável da transportadora;",
          "2. Apólice de seguro de responsabilidade civil, atualizada, assinada e carimbada pelo corretor de seguros, além dos comprovantes de pagamentos até a data da atualização."
        ]
      },
      // {
      //   name: "Laudo Engenheiro Mecânico",
      //   urlName: "laudo-engenheiro-mecanico",
      //   urlExternal: null,
      //   icon: "assignment",
      //   description: "",
      //   documents: ["1. Cartão CNPJ", "2. Cópia CRLV", "3. ART"]
      // },
      {
        name: "Inclusão de Veículo",
        urlName: "inclusao-veiculo",
        urlExternal: null,
        icon: "assignment",
        description: "",
        documents: [
          "1. Requerimento à Coordenadoria de Transportes da ARCE solicitando a inclusão de veículo, anexando os documentos abaixo exigidos, com firma reconhecida, nome, telefone e e-mail do responsável da transportadora;",
          "2. Cópia do contrato social da transportadora, apenas se houve alteração desde a renovação da empresa junto à ARCE;",
          "3. Certificado de propriedade (CRLV) ou contrato de arrendamento mercantil;",
          "4. Apólice de seguro de responsabilidade civil, atualizada, assinada e carimbada pelo corretor de seguros e boleto com comprovante de pagamento;",
          "5. Comprovante de pagamento da taxa de inclusão do veículo; ",
          "6. Laudo Técnico com ART;",
          "7. Nota Fiscal do veículo ou do chassi e carroceria, se o veículo for novo;"
        ]
      },
      {
        name: "Exclusão Veículo",
        urlName: "exclusao-veiculo",
        urlExternal: null,
        icon: "assignment",
        description: "",
        documents: [
          "1. Requerimento à Coordenadoria de Transportes da ARCE solicitando a exclusão do veículo, com firma reconhecida. Caso o requerimento seja encaminhado pelo procurador da transportadora, apresentar procuração concedendo poderes específicos para representar a outorgante junto à ARCE."
        ]
      },
      {
        name: "Licença",
        urlName: null,
        urlExternal:
          "https://sistemas2.arce.ce.gov.br/central-servicos/#/transportes/login?returnUrl=licenca-especial",
        icon: "assignment",
        description: "",
        documents: [
          "1. Acesse a central de serviços da ARCE com seu CNPJ e token e retire sua licença pelo link abaixo.",
          "https://sistemas2.arce.ce.gov.br/central-servicos/#/transportes/login?returnUrl=licenca-especial"
        ]
      },
      {
        name: "Renovação Certificado Tacógrafo",
        urlName: 'renovacao-certificado-tacografo',
        urlExternal: null,
        icon: "assignment",
        description: null,
        documents: ["1. Cartão CNPJ", "2. Cópia CRLV", "3. Certificado Tacógrafo"]
      },
    ]
  }),
  computed: {
    user: () => GlobalStore.user || {}
  }
};
</script>

<style lang="scss" scoped>
.v-alert__content {
  font-size: 15px;
  strong {
    font-size: 15px !important;
  }
}
.card-add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-style: dashed;
  flex-direction: column;
  &__icon {
    background-color: #067b26;
    padding: 10px;
    border-radius: 50% !important;
    margin-bottom: 10px;
  }
  &__title {
    font-weight: 400 !important;
    text-align: center;
  }
  &__overlay {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: not-allowed;
  }
  &__info {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
