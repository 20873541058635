<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
        @click.prevent.stop
        title="Clique aqui para ver os documentos exigidos"
      >
        <v-icon>info</v-icon>
      </v-btn>
    </template>
    <v-card class="app-card mb-0">
      <v-card-title class="app-card-title">Documentação Exigida</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="d in data" :key="d">
            <p>{{ d }}</p>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "Popover",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    menu: false
  })
};
</script>

<style></style>
